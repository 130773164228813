<template>
    <div class="formBox">
      <div style="display: flex; align-items: center">
        <h4 style="color: #146aff; padding: 0px 20px 0px 30px">专家详情</h4>
  
        <!-- <el-checkbox v-model="checked" @change="clcikChecked">通用版专家</el-checkbox> -->
      </div>
    <div class="form_back">
        <el-form ref="form" :model="form" label-width="120px" :rules="updatePwdRules">
        <el-form-item label="头像" prop="headPortrait">
          <uploadLogo
            :imageShow="form.headPortrait"
            @uploadOneImgShow="uploadOneImgShow"
          ></uploadLogo>
        </el-form-item>
        <el-form-item label="专家名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入专家名称"></el-input>
        </el-form-item>
        <el-form-item label="昵称" prop="nickName">
          <el-input v-model="form.nickName" placeholder="请输入昵称"></el-input>
        </el-form-item>
        <el-form-item label="专家性别" prop="gender">
          <div style="margin-top: 10px">
            <el-radio-group v-model="form.gender">
              <el-radio :label="'1'" :value="'1'">男士</el-radio>
              <el-radio :label="'2'" :value="'2'">女士</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="一句话介绍:" prop="specialistBrief">
          <div class="txtree">
            <el-input
              v-model="form.specialistBrief"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
              placeholder="请输入一句话简介"
              maxlength="50"
              show-word-limit
            />
          </div>
        </el-form-item>
        <el-form-item label="所在地区" prop="activityProvince" class="informationType">
          <div>
            <el-select v-model="form.province" placeholder="请选择省份" @change="citychange">
              <el-option
                v-for="item in operatingList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
            <div style="display: inline-block; width: 10px"></div>
            <el-select v-model="form.city" placeholder="请选择地区">
              <el-option
                v-for="item in operatingLists"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="封面图" prop="cover">
          <div class="cover">
            <div class="img_box">
              <img-big-upload
                :imageShow.sync="form.cover"
                :addTitleShow="false"
                @removeImg="removeImgDianspeci"
                @uploadOneImgShow="uploadOneImgShowDianspeci"
              />
              <span style="color: #146aff">建议图片整体尺寸为229*131 px大小不超过12MB</span>
            </div>
            <div v-if="form.cover">缩略图</div>
            <div v-if="form.cover" class="thumbnail">
              <div class="thumbnail_box">
                <img :src="form.cover" alt="" />
                <div>图片尺寸：229*131 px</div>
                <div>图片大小：4MB</div>
                <div>展示位置：首页</div>
              </div>
  
              <div class="thumbnail_box">
                <img :src="form.cover" alt="" />
                <div>图片尺寸：229*131 px</div>
                <div>图片大小：10MB</div>
                <div>展示位置：首页banner区域</div>
              </div>
            </div>
          </div>
        </el-form-item>
  
        
        <el-form-item label="擅长领域" prop="fieldList">
          <div
            style="display: flex; margin-top: 10px"
            v-for="(o, index) in form.fieldList"
            :key="index"
          >
            <div class="input_flex">
              <el-input v-model="o.name" placeholder="请擅长领域" />
            <el-input
            style="margin-top:10px ;"
              v-model="o.brief"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
              placeholder="请输入一句话简介"
              maxlength="60"
              show-word-limit
            />
            </div>
            
            <div style="display: flex; font-size: 24px">
              <span v-if="index == 0" style="color: #4e93fb" @click="addareaspeci"
                ><i class="el-icon-circle-plus"></i
              ></span>
              <span v-if="index > 0" style="color: #ff7575" @click="delareaspeci(index)"
                ><i class="el-icon-delete"></i
              ></span>
            </div>
          </div>
        </el-form-item>
  
        <el-form-item label="专家简介" prop="specialistIntro">
          <div class="editBarBox">
            <div class="editBar">
              <Editorbar v-model="form.specialistIntro" :isClear="isClear" />
              <div class="img_tips" style="margin-top: 30px; width: 700px">
                <span
                  >(图片的建议尺寸长为750px，富文本内图片格式应为jpg、jpeg、png，图片应小于12MB)
                </span>
              </div>
            </div>
          </div>
        </el-form-item>
  
        <h4 class="h4_share">附加信息</h4>
        <!-- 普通用户 -->
        <div class="add_information">
          <div style="padding: 20px">普通用户</div>
          <el-form-item label="约聊方式" prop="chatTypeList">
            <el-checkbox-group v-model="form.chatTypeList" @change="chatchange">
              <el-checkbox :label="'本地约聊'">本地约聊</el-checkbox>
              <el-checkbox :label="'线上会议'">线上会议</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
  
          <div v-if="form.chatTypeList.length">
            <div
              class="online_conference"
              v-if="form.chatTypeList[1] == '线上会议' || form.chatTypeList[0] == '线上会议'"
            >
              <div style="padding: 20px">线上会议</div>
              <el-form-item label="收费方式" prop="chargeWay">
                <div style="margin-top: 10px">
                  <el-radio-group v-model="form.chargeWay" @change="chargeWay">
                    <el-radio :label="1" :value="1">付费</el-radio>
                    <el-radio :label="2" :value="2">免费</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <div v-if="form.chargeWay==1">
                <el-form-item label="原价" prop="originalPrice">
                <div class="original">
                  <el-input v-model="form.originalPrice" placeholder="请输入原价" ></el-input>
                  <span class="online_color">元</span>
                </div>
              </el-form-item>
              <el-form-item label="优惠方式" prop="discountType">
                <div style="margin-top: 10px">
                  <el-radio-group v-model="form.discountType">
                    <el-radio :label="'折扣'" :value="1">折扣</el-radio>
                    <el-radio :label="'优惠价'" :value="2">优惠价</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <el-form-item v-if="form.discountType == '折扣'" label="折扣" prop="discount">
                <div class="original">
                  <el-input-number
                    v-model="form.discount"
                    :min="0"
                    :max="100"
                    label="描述文字"
                  ></el-input-number>
                  <span class="online_color">%</span>
                </div>
              </el-form-item>
              <el-form-item v-if="form.discountType == '优惠价'" label="优惠价" prop="discount">
                <div class="original">
                  <el-input v-model="form.discount" placeholder="请输入原价"></el-input>
                  <span class="online_color">元</span>
                </div>
              </el-form-item>
  
            
              </div>
              <el-form-item label="时间" prop="chatTime">
                <div class="original">
                  <el-input-number
                    v-model="form.chatTime"
                    :min="0"
                    :max="24"
                    label="描述文字"
                  ></el-input-number>
                  <span class="online_color">小时</span>
                </div>
                <div class="online_color">此处设置的时间选择“线上会议”约聊方式使用</div>
              </el-form-item>
            </div>
            <div
              class="online_conferences"
              v-if="form.chatTypeList[0] == '本地约聊' || form.chatTypeList[1] == '本地约聊'"
            >
              <div style="padding: 20px">本地约聊</div>
              <el-form-item label="费用类型" prop="localChargeWay">
                <div style="margin-top: 10px">
                  <el-radio-group v-model="form.localChargeWay" @change="chargeWay">
                    <el-radio :label="1" >付费</el-radio>
                    <el-radio :label="2" >免费</el-radio>
                    <el-radio :label="3" >面议</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <div v-if="form.localChargeWay==1">
                <el-form-item  label="原价" prop="localOriginalPrice">
                <div class="original">
                  <el-input v-model="form.localOriginalPrice" placeholder="请输入原价"></el-input>
                  <span class="online_color">元</span>
                </div>
              </el-form-item>
              <el-form-item  label="优惠方式" prop="localDiscountType">
                <div style="margin-top: 10px">
                  <el-radio-group v-model="form.localDiscountType">
                    <el-radio :label="'折扣'" >折扣</el-radio>
                    <el-radio :label="'优惠价'">优惠价</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <el-form-item v-if="form.localDiscountType == '折扣'" label="折扣" prop="localDiscount">
                <div class="original">
                  <el-input-number
                    v-model="form.localDiscount"
                    :min="0"
                    :max="100"
                    label="描述文字"
                  ></el-input-number>
                  <span class="online_color">%</span>
                </div>
              </el-form-item>
              <el-form-item
                v-if="form.localDiscountType == '优惠价'"
                label="优惠价"
                prop="localDiscount"
              >
                <div class="original">
                  <el-input v-model="form.localDiscount" placeholder="请输入原价"></el-input>
                  <span class="online_color">元</span>
                </div>
              </el-form-item>
              </div>
              
            </div>
          </div>
        </div>
        <!-- 个人会员用户 -->
        <div class="add_information">
          <div style="padding: 20px">个人会员用户</div>
          <el-form-item label="约聊方式" prop="memberChatTypeList">
            <el-checkbox-group v-model="form.memberChatTypeList" @change="chatchange">
              <el-checkbox :label="'本地约聊'">本地约聊</el-checkbox>
              <el-checkbox :label="'线上会议'">线上会议</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
  
          <div v-if="form.memberChatTypeList.length">
            <div
              class="online_conference"
              v-if="form.memberChatTypeList[1] == '线上会议' || form.memberChatTypeList[0] == '线上会议'"
            >
              <div style="padding: 20px">线上会议</div>
              <el-form-item label="收费方式" prop="memberChargeWay">
                <div style="margin-top: 10px">
                  <el-radio-group v-model="form.memberChargeWay" @change="chargeWay">
                    <el-radio :label="1" >付费</el-radio>
                    <el-radio :label="2" >免费</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <div v-if="form.memberChargeWay==1">
                <el-form-item label="原价" prop="memberOriginalPrice">
                <div class="original">
                  <el-input v-model="form.memberOriginalPrice" placeholder="请输入原价"></el-input>
                  <span class="online_color">元</span>
                </div>
              </el-form-item>
              <el-form-item label="优惠方式" prop="memberDiscountType">
                <div style="margin-top: 10px">
                  <el-radio-group v-model="form.memberDiscountType">
                    <el-radio :label="'折扣'" >折扣</el-radio>
                    <el-radio :label="'优惠价'" >优惠价</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <el-form-item v-if="form.memberDiscountType == '折扣'" label="折扣" prop="discount">
                <div class="original">
                  <el-input-number
                    v-model="form.memberDiscount"
                    :min="0"
                    :max="100"
                    label="描述文字"
                  ></el-input-number>
                  <span class="online_color">%</span>
                </div>
              </el-form-item>
              <el-form-item v-if="form.memberDiscountType == '优惠价'" label="优惠价" prop="discount">
                <div class="original">
                  <el-input v-model="form.memberDiscount" placeholder="请输入原价"></el-input>
                  <span class="online_color">元</span>
                </div>
              </el-form-item>
  
            
              </div>
              <el-form-item label="时间" prop="memberChatTime">
                <div class="original">
                  <el-input-number
                    v-model="form.memberChatTime"
                    :min="0"
                    :max="24"
                    label="描述文字"
                  ></el-input-number>
                  <span class="online_color">小时</span>
                </div>
                <div class="online_color">此处设置的时间选择“线上会议”约聊方式使用</div>
              </el-form-item>
            </div>
            <div
              class="online_conferences"
              v-if="form.memberChatTypeList[0] == '本地约聊' || form.memberChatTypeList[1] == '本地约聊'"
            >
              <div style="padding: 20px">本地约聊</div>
              <el-form-item label="费用类型" prop="localMemberChargeWay">
                <div style="margin-top: 10px">
                  <el-radio-group v-model="form.localMemberChargeWay" @change="chargeWay">
                    <el-radio :label="1" >付费</el-radio>
                    <el-radio :label="2" >免费</el-radio>
                    <el-radio :label="3" >面议</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <div v-if="form.localMemberChargeWay==1">
                <el-form-item  label="原价" prop="localMemberOriginalPrice">
                <div class="original">
                  <el-input v-model="form.localMemberOriginalPrice" placeholder="请输入原价"></el-input>
                  <span class="online_color">元</span>
                </div>
              </el-form-item>
              <el-form-item  label="优惠方式" prop="localMemberDiscountType">
                <div style="margin-top: 10px">
                  <el-radio-group v-model="form.localMemberDiscountType">
                    <el-radio :label="'折扣'" :value="1">折扣</el-radio>
                    <el-radio :label="'优惠价'" :value="2">优惠价</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <el-form-item v-if="form.localMemberDiscountType == '折扣'" label="折扣" prop="localDiscount">
                <div class="original">
                  <el-input-number
                    v-model="form.localMemberDiscount"
                    :min="0"
                    :max="100"
                    label="描述文字"
                  ></el-input-number>
                  <span class="online_color">%</span>
                </div>
              </el-form-item>
              <el-form-item
                v-if="form.localMemberDiscountType == '优惠价'"
                label="优惠价"
                prop="localMemberDiscount"
              >
                <div class="original">
                  <el-input v-model="form.localMemberDiscount" placeholder="请输入原价"></el-input>
                  <span class="online_color">元</span>
                </div>
              </el-form-item>
              </div>
              
            </div>
          </div>
        </div>
        <!-- 企业会员用户 -->
        <div class="add_information">
          <div style="padding: 20px">企业会员用户</div>
          <el-form-item label="约聊方式" prop="">
            <el-checkbox-group v-model="form.companyChatTypeList" @change="chatchange">
              <el-checkbox :label="'本地约聊'">本地约聊</el-checkbox>
              <el-checkbox :label="'线上会议'">线上会议</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
  
          <div v-if="form.companyChatTypeList.length">
            <div
              class="online_conference"
              v-if="form.companyChatTypeList[1] == '线上会议' || form.companyChatTypeList[0] == '线上会议'"
            >
              <div style="padding: 20px">线上会议</div>
              <el-form-item label="收费方式" prop="">
                <div style="margin-top: 10px">
                  <el-radio-group v-model="form.companyChargeWay" @change="chargeWay">
                    <el-radio :label="1" >付费</el-radio>
                    <el-radio :label="2" >免费</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <div v-if="form.companyChargeWay==1">
                <el-form-item label="原价" prop="companyOriginalPrice">
                <div class="original">
                  <el-input v-model="form.companyOriginalPrice" placeholder="请输入原价"></el-input>
                  <span class="online_color">元</span>
                </div>
              </el-form-item>
              <el-form-item label="优惠方式" prop="companyDiscountType">
                <div style="margin-top: 10px">
                  <el-radio-group v-model="form.companyDiscountType">
                    <el-radio :label="'折扣'" :value="1">折扣</el-radio>
                    <el-radio :label="'优惠价'" :value="2">优惠价</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <el-form-item v-if="form.companyDiscountType == '折扣'" label="折扣" prop="discount">
                <div class="original">
                  <el-input-number
                    v-model="form.companyDiscount"
                    :min="0"
                    :max="100"
                    label="描述文字"
                  ></el-input-number>
                  <span class="online_color">%</span>
                </div>
              </el-form-item>
              <el-form-item v-if="form.companyDiscountType == '优惠价'" label="优惠价" prop="discount">
                <div class="original">
                  <el-input v-model="form.companyDiscount" placeholder="请输入原价"></el-input>
                  <span class="online_color">元</span>
                </div>
              </el-form-item>
  
            
              </div>
              <el-form-item label="时间" prop="companyChatTime">
                <div class="original">
                  <el-input-number
                    v-model="form.companyChatTime"
                    :min="0"
                    :max="24"
                    label="描述文字"
                  ></el-input-number>
                  <span class="online_color">小时</span>
                </div>
                <div class="online_color">此处设置的时间选择“线上会议”约聊方式使用</div>
              </el-form-item>
            </div>
            <div
              class="online_conferences"
              v-if="form.companyChatTypeList[0] == '本地约聊' || form.companyChatTypeList[1] == '本地约聊'"
            >
              <div style="padding: 20px">本地约聊</div>
              <el-form-item label="费用类型" prop="localCompanyChargeWay">
                <div style="margin-top: 10px">
                  <el-radio-group v-model="form.localCompanyChargeWay" @change="chargeWay">
                    <el-radio :label="1" :value="1">付费</el-radio>
                    <el-radio :label="2" :value="2">免费</el-radio>
                    <el-radio :label="3" >面议</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <div v-if="form.localCompanyChargeWay==1">
                <el-form-item  label="原价" prop="localCompanyOriginalPrice">
                <div class="original">
                  <el-input v-model="form.localCompanyOriginalPrice" placeholder="请输入原价"></el-input>
                  <span class="online_color">元</span>
                </div>
              </el-form-item>
              <el-form-item  label="优惠方式" prop="localCompanyDiscountType">
                <div style="margin-top: 10px">
                  <el-radio-group v-model="form.localCompanyDiscountType">
                    <el-radio :label="'折扣'" >折扣</el-radio>
                    <el-radio :label="'优惠价'" >优惠价</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <el-form-item v-if="form.localCompanyDiscountType == '折扣'" label="折扣" prop="localDiscount">
                <div class="original">
                  <el-input-number
                    v-model="form.localCompanyDiscount"
                    :min="0"
                    :max="100"
                    label="描述文字"
                  ></el-input-number>
                  <span class="online_color">%</span>
                </div>
              </el-form-item>
              <el-form-item
                v-if="form.localCompanyDiscountType == '优惠价'"
                label="优惠价"
                prop="localCompanyDiscount"
              >
                <div class="original">
                  <el-input v-model="form.localCompanyDiscount" placeholder="请输入原价"></el-input>
                  <span class="online_color">元</span>
                </div>
              </el-form-item>
              </div>
              
            </div>
          </div>
        </div>
  
        <h4 class="h4_share">小程序分享链接信息</h4>
        <el-form-item label="分享链接标语:" prop="">
          <el-input v-model="form.appLinkSlogan" placeholder="请输入分享链接标语" />
        </el-form-item>
        <el-form-item label="分享链接图片:" prop="">
          <div class="pictures_box_left">
            <img-big-upload
              :imageShow.sync="form.appLinkPicture"
              :addTitleShow="false"
              @removeImg="removeImgDians"
              @uploadOneImgShow="uploadOneImgShowDians"
            />
          </div>
          <div class="img_tips">
            <span>(建议整体尺寸为630*510px，图片格式应为jpg、jpeg、png，图片应小于12MB) </span>
          </div>
        </el-form-item>
      </el-form>
      <!-- <div style="margin-bottom: 40px;">
        <div class="tips">
          <span>最近编辑人：</span>
        </div>
        <div class="tips">
          <span>最近编辑时间：</span>
        </div>
      </div> -->
    
      <div class="button_box">
       <div class="save button" @click="clickSave">保存</div>
        <div class="cancel button" @click="clickCancel">取消</div>
      </div>
    </div>
     
    </div>
  </template>
  <script>
  import { save, listById, update } from '@/api/specialist.js'
  import uploadLogo from './uploadLogo.vue'
  import Editorbar from './Editorbar.vue'
  import {  getCityAll } from '@/api/demand'
  export default {
    components: { Editorbar, uploadLogo },
    computed: {
    activeId() {
      return this.$route.query.id
    }
  },
    data() {
      return {
        value: '',
        operatingList: [], //省
        operatingLists: [], //市
  
        form: {
          schemaName:JSON.parse(sessionStorage.getItem('typeMode')).schemaName,
          specialistChannel:2,
          province: null,
          city: null,
          chatTypeList: [], //普通用户约聊方式
          memberChatTypeList:[],//"约聊方式-个人会员用户")
          memberChargeWay:1,//收费方式,1收费，2免费--个人会员用户
          memberOriginalPrice:0,//"原价--个人会员用户")
          memberDiscountType:"优惠价",//优惠方式 折扣；优惠价--个人会员用户"
          memberDiscount:0,//"折扣--个人会员用户")
          memberChatTime:'2',//"约聊时间--线上会议-个人会员用户")
          //------------个人会员本地约聊
          localMemberChargeWay:1,//= "本地约聊-收费方式,1收费，2免费"
          localMemberDiscountType:'优惠价',//折扣；优惠价--本地约聊")
          localMemberDiscount:0,//本地约聊-普通用户的折扣")
          localMemberOriginalPrice:0,//"本地约聊-原价")
           //------------企业会员线上会议
           companyChatTypeList:[],//"约聊方式-企业会员用户；本地约聊，线上会议")
           companyChargeWay:1,//收费方式,1收费，2免费--企业会员用户")
           companyDiscountType:'优惠价',//"优惠方式 折扣；优惠价--企业会员用户
           companyDiscount:0,//折扣--企业会员用户")
           companyOriginalPrice:"",//("原价--企业会员用户")
           companyChatTime:'2',//约聊时间--线上会议-企业会员用户"
           //------------企业会员本地约聊
           localCompanyChargeWay:1,//本地约聊-收费方式,1收费，2免费")
           localCompanyOriginalPrice:0,//本地约聊-原价")
           localCompanyDiscountType:'优惠价',//折扣；优惠价--本地约聊")
           localCompanyDiscount:0,//本地约聊-普通用户的折扣"
  
          localOriginalPrice: '0',
          discountType: '优惠价',
          discount: 0,
          chatTime:'2',
          localChargeWay: 1,
          localDiscountType: '优惠价',
          localDiscount: 0,
          originalPrice: '0',
          cover: '',
          specialistBrief: null,
          appLinkPicture: '',
          appLinkSlogan: '',
          chargeWay: 1,
          consultingFee: null,
          fieldList: [
            {
              name: '',
              brief:''
            }
          ],
          gender: '',
          headPortrait: '',
          generalVersion: 0,
        
          name: '',
          nickName: '',
          specialistIntro: '',
  
          tagsList: []
        },
        checked: false, //通用版
        // logo图片
        imageOneShow: '',
        imageUrlList: [],
        isClear: false,
        updatePwdRules: {
          // chatTypeList :[{ required: true, message: '请选择普通用户约聊方式', trigger: 'blur' }],
          specialistBrief: [{ required: true, message: '请输入一句话介绍', trigger: 'blur' }],
          name: [{ required: true, message: '请输入专家名称', trigger: 'blur' }],
          nickName: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
          gender: [{ required: true, message: '请勾选性别', trigger: 'blur' }],
          chargeWay: [{ required: true, message: '请输入收费方式', trigger: 'blur' }],
          fieldList: [{ required: true, message: '请输入擅长领域' }],
          specialistIntro: [{ required: true, message: '请输入专家简介', trigger: 'change' }],
          cover: [{ required: true, message: '请上传专家图片', trigger: 'change' }],
          originalPrice: [{ required: true, message: '请输入原价', trigger: 'change' }],
          discountType: [{ required: true, message: '请选择优惠方式', trigger: 'change' }],
          discount: [{ required: true, message: '请输入', trigger: 'change' }],
          chatTime: [{ required: true, message: '请输入会议时间', trigger: 'change' }],
          localChargeWay: [{ required: true, message: '请选择费用类型', trigger: 'change' }],
          localOriginalPrice: [{ required: true, message: '请输入原价', trigger: 'change' }],
          localDiscountType: [{ required: true, message: '请选择优惠方式', trigger: 'change' }],
          localDiscount: [{ required: true, message: '请输入', trigger: 'change' }],
          // localDiscount: [{ required: true, message: '请输入优惠价', trigger: 'change' }],
          // memberChatTypeList: [{ required: true, message: '请选择个人会员约聊方式', trigger: 'change' }],
          memberChargeWay:[{ required: true, message: '请选择收费方式', trigger: 'change' }],
          memberOriginalPrice:[{ required: true, message: '请输入原价', trigger: 'change' }],
          memberDiscountType:[{ required: true, message: '请选择优惠方式', trigger: 'change' }],
          memberDiscount:[{ required: true, message: '请输入', trigger: 'change' }],
          memberChatTime:[{ required: true, message: '请输入时间', trigger: 'change' }],
          localMemberChargeWay:[{ required: true, message: '请选择费用类型', trigger: 'change' }],
          localMemberOriginalPrice:[{ required: true, message: '请输入原价', trigger: 'change' }],
          localMemberDiscountType:[{ required: true, message: '请选择优惠方式', trigger: 'change' }],
          localMemberDiscount:[{ required: true, message: '请输入', trigger: 'change' }],
          companyChargeWay:[{ required: true, message: '请选择收费方式', trigger: 'change' }],
          companyOriginalPrice:[{ required: true, message: '请输入原价', trigger: 'change' }],
          companyDiscountType:[{ required: true, message: '请选择优惠方式', trigger: 'change' }],
          companyDiscount:[{ required: true, message: '请输入', trigger: 'change' }],
          companyChatTime:[{ required: true, message: '请输入时间', trigger: 'change' }],
          localCompanyChargeWay:[{ required: true, message: '请选择费用类型', trigger: 'change' }],
          localCompanyOriginalPrice:[{ required: true, message: '请输入原价', trigger: 'change' }],
          localCompanyDiscountType:[{ required: true, message: '请选择优惠方式', trigger: 'change' }],
          localCompanyDiscount:[{ required: true, message: '请输入', trigger: 'change' }],
          
        },
        issueNameOption: [],
        facilitatorOption: []
      }
    },
    
    created() {
      if (this.activeId) {
        this.getSelectById()
        this.imageOneShow = this.form.logo
      }
      this.incity()
    },
  
    mounted() {},
    //方法集合
    methods: {
      //城市
      async incity() {
        const res = await getCityAll()
        if (res.data.resultCode == 200) {
          this.operatingList = res.data.data
        }
      },
      citychange(item) {
        console.log('触发了', this.operatingList)
        this.operatingList.forEach((o) => {
          if (this.form.province == o.name) {
            this.form.city = ''
            if (item == '全部') {
              this.operatingLists = this.operatingList.map((el) => el.cityResults).flat(Infinity)
              console.log(this.operatingLists, 'this.operatingLists ')
            } else {
              this.operatingLists = this.operatingList.find((el) => el.name == item).cityResults
            }
          }
        })
      },
      async getSelectById() {
        const { data: res } = await listById({ id: this.activeId })
  
        if (res.resultCode == 200) {
          this.form = res.data
          if (!this.form.chatTypeList) {
            this.form.chatTypeList = []
          }
          if (!this.form.memberChatTypeList) {
            this.form.memberChatTypeList = []
          }
          if (!this.form.companyChatTypeList) {
            this.form.companyChatTypeList = []
          }
          if (this.form.generalVersion == '0') {
            this.checked = true
          }
          if (this.form.cover == null) {
            this.form.cover = ''
          }
  
          if (this.form.generalVersion == '1') {
            this.checked = false
          }
          if (this.form.fieldListParams) {
            let list = []
            this.form.fieldListParams.forEach((el) => {
              if(!el.fieldIntro){
                el.fieldIntro=''
              }
              list.push({
                name: el.field,
                brief :el.fieldIntro
              })
            })
            this.form.fieldList = list
          }else{
            this.form.fieldList=[{
              name: '',
                brief :''
            }]
          }
        }
      },
      //取消专家聊天方式
      chatchange(val) {
        console.log(val);
        if (this.form.chatTypeList.length == 1) {
          this.form.chatTypeList.forEach((el) => {
            if (el == '本地约聊') {
              this.form.chargeWay = null
              this.form.originalPrice = '0'
              this.form.discountType = null
              this.form.discount = 0
              this.form.chatTime = null
            } else {
              this.form.localChargeWay = null
              this.form.localOriginalPrice = '0'
              this.form.localDiscountType = null
              this.form.localDiscount =0
            }
          })
        }
        if (this.form.memberChatTypeList.length == 1) {
          this.form.memberChatTypeList.forEach((el) => {
            if (el == '本地约聊') {
              this.form.memberChargeWay = null
              this.form.memberOriginalPrice = '0'
              this.form.memberDiscountType = null
              this.form.memberDiscount = 0
              this.form.memberChatTime = null
            } else {
              this.form.localMemberChargeWay = null
              this.form.localMemberOriginalPrice = '0'
              this.form.localMemberDiscountType = null
              this.form.localMemberDiscount =0
            }
          })
        }
        if (this.form.companyChatTypeList.length == 1) {
          this.form.companyChatTypeList.forEach((el) => {
            if (el == '本地约聊') {
              this.form.companyChargeWay = null
              this.form.companyOriginalPrice = '0'
              this.form.companyDiscountType = null
              this.form.companyDiscount = 0
              this.form.companyChatTime = null
            } else {
              this.form.localCompanyChargeWay = null
              this.form.localCompanyOriginalPrice = 0
              this.form.localCompanyDiscountType = null
              this.form.localCompanyDiscount =0
            }
          })
        }
      },
     
      //新增专家
      addareaspeci() {
        this.form.fieldList.push({
          name: '',
              brief:''
        })
      },
      //删除专家
      delareaspeci(index) {
        this.form.fieldList.splice(index, 1)
      },
      //选择收费方式
      chargeWay(){
        if(this.form.chargeWay==2){//普通用户免费原价为0
          this.form.originalPrice='0'
          this.form.discountType='优惠价'
          this.form.discount=0
        }
        if(this.form.localChargeWay==2){
          this.form.localOriginalPrice='0'
          this.form.localDiscountType='优惠价'
          this.form.localDiscount=0
        }
        if(this.form.memberChargeWay==2){
          this.form.memberOriginalPrice='0'
          this.form.memberDiscountType='优惠价'
          this.form.memberDiscount=0
        }else if(this.form.memberChargeWay==1) {
          this.form.memberOriginalPrice=this.form.originalPrice
        }
        if(this.form.localMemberChargeWay==2){
          this.form.localMemberOriginalPrice='0'
          this.form.localMemberDiscountType='优惠价'
          this.form.localMemberDiscount=0
        }else if(this.form.localMemberChargeWay==1){
          this.form.localMemberOriginalPrice= this.form.localOriginalPrice
        }
        if(this.form.companyChargeWay==2){
          this.form.companyOriginalPrice='0'
          this.form.companyDiscountType='优惠价'
          this.form.companyDiscount=0
        }else if(this.form.companyChargeWay==1) {
          this.form.companyOriginalPrice=this.form.originalPrice
        }
        if(this.form.localCompanyChargeWay==2){
          this.form.localCompanyOriginalPrice='0'
          this.form.localCompanyDiscountType='优惠价'
          this.form.localCompanyDiscount=0
        }else if(this.form.localCompanyChargeWay==1){
          this.form.localCompanyOriginalPrice= this.form.localOriginalPrice
        }
      },
      clcikChecked() {
        if (this.checked == true) {
          this.form.generalVersion = '0'
        } else if (this.checked == false) {
          this.form.generalVersion = '1'
        }
      },
      //获取长地址图片
      uploadOneImgShowDians(val) {
        console.log('uploadOneImgShowDians', val)
        this.form.appLinkPicture = val
      },
      /**
       * 删除图
       */
      removeImgDians(val) {
        this.form.appLinkPicture = ''
      },
      // 保存
      clickSave() {
       
        let list = []
        let list1 = this.form.fieldList
        if (this.form.fieldList.length > 0) {
          this.form.fieldList.forEach((el) => {
  
            list.push(el.name+'~'+el.brief )
          })
          console.log(list);
        }
        
        this.$refs['form'].validate((valid, obj) => {
          if (valid) {
            if(!this.form.fieldList[0].name){
          this.$message.warning('请输入擅长领域')
          return
        }
            if (this.form.id == null) {
              this.form.fieldList = list
  
              save({ ...this.form, activityImg: this.imageUrlList[0] }).then((response) => {
                if (response.data.resultCode == 200) {
                  this.$message.success('新增成功')
                  this.$router.go(-1)
                } else {
                  this.form.fieldList = list1
                  this.$message.error(response.data.message)
                }
              })
            } else {
              this.form.fieldList = list
              update({ ...this.form, activityImg: this.imageUrlList[0] }).then((response) => {
                if (response.data.resultCode == 200) {
                  this.$message.success('编辑成功')
                  this.$router.go(-1)
                } else {
                  this.form.fieldList = list1
                  this.$message.error(response.data.message)
                }
              })
            }
          } else {
            // console.log(obj);
          }
        })
      },
      // 取消
      clickCancel() {
        this.$router.back()
        // console.log('取消')
      },
      // 图片上传路径长地址  logo
      uploadOneImgShow(value) {
        // this.imageOneShow = value
        this.form.headPortrait = value
      },
      //删除专家封面图
      removeImgDianspeci(val) {
        this.form.cover = ''
      },
      //添加专家封面图
      uploadOneImgShowDianspeci(val) {
        this.form.cover = val
      },
      //多个图片上传显示
      uploadOneImg(val) {
        this.imageUrlList = []
  
        val.forEach((item) => {
          this.imageUrlList.push(item.url)
        })
      },
      // 移除
      removeImg(val) {
        this.imageUrlList = []
      }
    }
  }
  </script>
  <style scoped lang="less">
  .formBox {
    padding-top: 24px;
    width: 100%;
    height: 100%;
    background: #fff;
    .form_back{
        background: #fff;
    }
    .input_flex{
      display: flex;
      flex-direction: column;
    }
    .img_tips {
      font-size: 12px;
      color: #6ba1ff;
    }
    .h4_share {
      padding: 50px 50px 0px 30px;
      color: #146aff;
    }
    .add_information {
      width: 633.54px;
      min-height: 100px;
      border: 1px dashed #c4c4c4;
      border-radius: 2px;
      margin-left: 120px;
      margin-bottom: 15px;
      .online_conference {
        width: 447.76px;
        min-height: 105.17px;
        border: 1px dashed #c4c4c4;
        border-radius: 2px;
        margin-left: 120px;
        .online_color {
          color: #999999;
        }
      }
      .online_conferences {
        margin-top: 16px;
        width: 447.76px;
        min-height: 105.17px;
        border: 1px dashed #c4c4c4;
        border-radius: 2px;
        margin-left: 120px;
        margin-bottom: 10px;
        .online_color {
          color: #999999;
        }
      }
    }
    .inputBox {
      height: 40px;
      display: flex;
    }
    .general_inputs {
      width: 585px;
      border: 1px solid #d4d4d4;
      padding: 14px 20px;
      .input_flex {
        display: flex;
        justify-content: space-between;
        padding: 7px 0px;
        span {
          padding-left: 15px;
          font-size: 20px;
          cursor: pointer;
        }
        .el_buttom {
          position: relative;
          left: -110px;
        }
      }
    }
  
    /deep/.el-input {
      width: 600px;
      height: 40px;
    }
  
    .labenBox {
      width: 120px;
      display: flex;
      justify-content: flex-end;
      margin-right: 12px;
  
      .label {
        color: #333333;
        font-size: 14px;
        margin-right: 2px;
      }
      .span {
        color: #f05252;
      }
    }
  
    .logo_box {
      display: flex;
      .label_box {
        display: flex;
        margin-right: 12px;
        width: 120px;
        justify-content: flex-end;
        .logo_label {
          color: #333333;
          font-size: 14px;
          margin-right: 2px;
        }
        .span {
          color: #f05252;
        }
      }
    }
    .thumbnail {
      display: flex;
      color: #bebebe;
    }
    .thumbnail img {
      width: 143.23px;
      height: 81.63px;
      border-radius: 4px;
    }
    .thumbnail video {
      width: 160.28px;
      height: 91.35px;
      border-radius: 4px;
    }
  
    .thumbnail div {
      margin-right: 20px;
    }
    .thumbnail_box div {
      display: flex;
      flex-direction: column;
      height: 30px;
    }
    .cover {
      display: flex;
      flex-direction: column;
    }
  
    .picker {
      width: 240px !important;
    }
    /deep/.el-radio-group {
      display: flex;
      // flex-direction: column;
      align-items: center;
    }
    /deep/.el-radio__input {
      width: 18px;
      height: 18px;
    }
    /deep/.el-radio__inner {
      width: 18px;
      height: 18px;
    }
    /deep/.el-radio__label {
      font-size: 14px;
      color: #333333;
    }
  
    .editInformation {
      margin-top: 40px;
      margin-left: 58px;
      color: #999999;
      font-size: 14px;
  
      .item {
        margin-bottom: 6px;
      }
    }
    .button_box {
      width: 100%;
      // height: 160px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid #ececec;
      padding-top: 15px;
      padding-bottom: 55px;
      .send {
        background-color: #f79646;
        color: #fff;
        cursor: pointer;
      }
      .preview {
        background-color: #37cc7e;
        color: #fff;
        cursor: pointer;
      }
      .save {
        background-color: #4e93fb;
        color: #fff;
        cursor: pointer;
      }
      .cancel {
        background-color: #fff;
        color: #4e93fb;
        cursor: pointer;
        border: 1px solid #4e93fb;
      }
      .button {
        width: 112px;
        height: 40px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 16px;
      }
    }
    .editBarBox {
      width: 100%;
      display: flex;
  
      .editbar_text {
        width: 130px;
        display: flex;
        justify-content: flex-end;
        .text_a {
          margin-right: 2px;
        }
        .span {
          margin-right: 12px;
          color: #f05252;
        }
      }
  
      .editBar {
        // width: 686px;
        // width: 100%;
        width: 375px;
        height: 437px;
      }
    }
  }
  .inline_form_box {
    display: flex;
    // align-items: center;
    ::v-deep .el-input {
      color: #448aff;
      background: #ecf3ff;
      border-color: #b4d0ff;
      border: none;
      width: 217px;
    }
  }
  .informationType {
    ::v-deep .el-input__inner,
    ::v-deep .el-input {
      width: 294px;
    }
  }
  .tips {
    margin: 0 0 10px 100px;
    color: #999999;
    font-size: 14px;
  }
  ::v-deep .el-button--primary.is-plain {
    color: #448aff;
    background: #ecf3ff;
    border-color: #b4d0ff;
    border: none;
  }
  ::v-deep .name_form .el-input__inner {
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 550px;
  }
  ::v-deep .original .el-input__inner {
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 180px;
  }
  ::v-deep .original .el-input {
    position: relative;
    font-size: 14px;
    display: inline-block;
    width: 180px;
  }
  ::v-deep .online_conference .el-form-item {
    margin-bottom: 20px;
  }
  ::v-deep .editor .text {
    border: 1px solid #ccc;
    min-height: 390px;
    max-height: 390px;
  }
  ::v-deep .w-e-text-container .w-e-text {
    max-height: 390px;
  }
  ::v-deep .txtree .el-textarea__inner {
    display: block;
    resize: vertical;
    padding: 5px 15px;
    line-height: 1.5;
    box-sizing: border-box;
    width: 600px;
    font-size: inherit;
    color: #606266;
    background-color: #fff;
    background-image: none;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  ::v-deep .el-textarea {
    position: relative;
    display: inline-block;
    width: 600px;
    vertical-align: bottom;
    font-size: 14px;
  }
  </style>
  